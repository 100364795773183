import { Component,Input,OnChanges,OnInit,ViewChild } from '@angular/core';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { Device, User } from '@models';
import { environment } from '@environment';
import { iterate } from '@lib/iterate';
import { math } from '@lib/math';
import * as htmlToPdf from '@utilities/html-to-pdf';
import { ReportPageContainerComponent } from './page-container/report-page-container.component';
import { InReport,ReportService } from './report.service';
import { cleanFilename, downloadContent } from '@lib/dom/download';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { NavigationRoute$ } from '@injectables';
import { BehaviorSubject } from 'rxjs';
import { MeasurementTypeToUnit } from '@lib/angular/measurement/measurement-type-to-unit';
import { ExtendedTypeMeta } from '@lib/measurement/types';
import { measurement } from '@lib/measurement';

@Component({
	selector: 'report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
	providers: [
		{
			provide: InReport,
			useFactory: ()=>true
		},
	],
})
export class ReportComponent implements OnChanges, OnInit {
	private reportType:string;
	@Input() title='';
	@Input() get orientation(){return this.report.orientation;}
			 set orientation(v:'portrait'|'landscape'){this.report.orientation=v;}
	@ViewChild('pageContainer') pageContainer:ReportPageContainerComponent;

	public readonly pageSizes=Object.keys(htmlToPdf.pageSizes);
	
	public readonly zoomOptions=[
		['Fit','fit'],
		['Rows 2','rows2' ],
		...[...iterate.numbers(1,2,0.1,true)].map(v=>[math.round(v*100).toFixed(0)+'%',v])
	];
	public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
	public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
	public isDevMode=false;
	public isMapInteractionOn=false;
	public selectedPageSize='LETTER';
	private deviceName:string='';
	public intervals:number=0;
	public zoom:ReportPageContainerComponent['zoom']='fit';
	public fileType: string = null;
	public psdDataFormatInCSV: 'single-row'|'multiple-row' = 'single-row';

	constructor(
		protected readonly dialog:DialogService,
		protected readonly user:User,
		public readonly report:ReportService,
		public readonly route$: NavigationRoute$,
		public readonly fragQuery:FragQueryService,
	){
		this.isDevMode=!environment.production;
	}
	public ngOnInit(){
		this.fileType = 'PDF';
		this.selectedPageSize='A4';

		this.fragQuery.device$.subscribe(
			device=>{
				this.deviceName=device.name
				this.intervals=device.targetIntervals
			}
		)
		this.initializeReport();
	}

	private initializeReport(){
		this.reportType='Fragmentation Camera';
		this.report.init(this.reportType);
	}

	public toggleFileType(type: string) {
        this.fileType = type;
    }

	public ngOnChanges(){
		this.report.title=this.title;
	}

	public downloadReady(){
		return this.report.htmlToPdfLoaded;
	}

	public updateInteraction() {
		this.isMapInteractionOn=!this.isMapInteractionOn;
	}

	private filename(ext:string){
		return cleanFilename(`${this.deviceName}_report_${(new Date).toDateString()}`,ext);
	}

	public async downloadCsv() {
		const dateTimeFormat: Intl.DateTimeFormatOptions = {
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			hourCycle: 'h23',
			minute: '2-digit',
			second: '2-digit',
		};
		const input = await this.dialog.await(this.fragQuery.exportTimelineSieveSizesAtPercents());
		let customSizes: any[];
	
		if (input && input.intervals && input.percents && this.fileType !== null) {
			const percents = input.percents.slice().sort((a, b) => a - b);
			let tsrows: string[] = [];
			const particleSizes = input.summary.psd.length;
				
			this.fragQuery.device$.subscribe((device: Device) => {
				if (device.timelineCustomSizes) {
					let customSizeOptions = JSON.parse(device.timelineCustomSizes);
					if (customSizeOptions[0].size != null) {
						customSizes = customSizeOptions;
					} else {
						customSizes = [];
					}
				} else {
					customSizes = [];
				}
			});
				
			for (let i = 0; i < particleSizes; i++) {
				tsrows.push(`TS${i + 1}`);
				tsrows.push(`PP${i + 1}`);
			}
	
			let weightTypeTitle = '';
			let particleSizeUnit = '';
			let totalPayload: number;
	
			this.fragQuery.particleSizeUnitSystem$.subscribe(unit => {
				particleSizeUnit = unit;
			});
			this.fragQuery.QuantityTitle$.subscribe(weightTitle => {
				weightTypeTitle = weightTitle;
			});
			this.fragQuery.totalPayload$.subscribe(Payload => {
				totalPayload = Payload;
			});

			const unitSystem = particleSizeUnit as keyof(ExtendedTypeMeta);
    		const measurementTypeToUnit = new MeasurementTypeToUnit;
    		const unit = measurementTypeToUnit.getUnitsForSystem(unitSystem).particleSize;
			
			const customSizeHeaders = customSizes.map(size => measurement.convert(size.size,'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}`)

			this.route$.subscribe(type=>{
				if(type[1]==='time-interval'){

					if(this.psdDataFormatInCSV==='single-row'){
						const csvRows: (string | number)[][] = [
							['Begin Time', 'End Time', 'Total Haulers', 'Total Payload', weightTypeTitle, ...percents.map((v) => `D${v}`), ...tsrows]
						];
						let psdData: number[] = [];
						input.summary.psd.forEach((psd,index)=> {
							const passing_percent = (psd.passing_percent && psd.passing_percent !== 0) ? psd.passing_percent : (index > 0 ? input.summary.psd[index - 1].passing_percent : 0);
							psd.passing_percent=passing_percent;
							psdData.push(parseFloat(psd.size.toFixed(2)));
							psdData.push(parseFloat(passing_percent.toFixed(2)));
						})
						const row: (string | number)[] = [
							input.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
							input.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
							input.intervals.length,
							totalPayload,
							input.summary.totalWeight.toFixed(2),
							...Object.values(input.summary.dvalues),
							...psdData,
						];
						csvRows.push(row);
						const csv = csvRows.map((row) => row.join(',')).join('\n');
						downloadContent(
							cleanFilename('Aggregated_Data', 'csv', ' '),
							csv
						);
					}
					else if(this.psdDataFormatInCSV==='multiple-row'){
						const csvRows:(string | number)[][] = [
							['Sr. Number', `Size (${particleSizeUnit})`, 'Target Interval %', 'Cumulative %']
						];
						let rowIterator=0;
						input.summary.psd.forEach((psd,i,psdArray)=>{
							rowIterator++;
							const row=[rowIterator,psd.size,i?parseFloat((psd.passing_percent?(psd.passing_percent-psdArray[i-1].passing_percent):0).toFixed(2)):psd.passing_percent,psd.passing_percent??psdArray[i-1].passing_percent];
							psd.passing_percent?null:psdArray[i].passing_percent=psdArray[i-1].passing_percent;
							csvRows.push(row);
						});
						Object.entries(input.summary.dvalues).forEach((dvalue,i,dvalues)=>{
							rowIterator++;
							const row=[rowIterator, dvalue[1],i?(parseInt(dvalue[0])-parseInt(dvalues[i-1][0])):dvalue[0],dvalue[0]];
							csvRows.push(row);
						});
						csvRows.push(['','','','']);
						csvRows.push(['Begin Time',input.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''),'','']);
						csvRows.push(['End Time',input.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''),'','']);
						csvRows.push(['Total Haulers',input.intervals.length,'','']);
						csvRows.push(['Total Payload',totalPayload,'','']);
						csvRows.push([`${weightTypeTitle}`,input.summary.totalWeight.toFixed(2),'','']);

						const csv2 = csvRows.map((row) => row.join(',')).join('\n');
						downloadContent(
							cleanFilename('Aggregated_Data', 'csv', ' '),
							csv2
						);
					}
				} else {
					const csvRows: (string | number)[][] = [
						['Begin Time', 'End Time', ...percents.map((v) => `D${v}`), ...tsrows, ...customSizeHeaders]
					];
					input.intervals.forEach(interval => {
						let psdData: number[] = [];
						interval.psd.forEach((psd, index) => {
							const passing_percent = (psd.passing_percent && psd.passing_percent !== 0) ? psd.passing_percent : (index > 0 ? interval.psd[index - 1].passing_percent : 0);
							psd.passing_percent = passing_percent;
							psdData.push(parseFloat(psd.size.toFixed(2)));
							psdData.push(parseFloat(passing_percent.toFixed(2)));
						});
							
						const customSizeValues = (interval.customSizePercents ?? []).map(value => `${value}%`);
	
						const row: (string | number)[] = [
							interval.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
							interval.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
							...interval.data.map((v) => typeof v === 'number' ? Math.round(v) : ''),
							...psdData,
							...customSizeValues
						];
						csvRows.push(row);
					});
	
					const csv = csvRows.map((row) => row.join(',')).join('\n');
					downloadContent(
						cleanFilename('Sieve sizes at percents', 'csv', ' '),
						csv
					);
				}
			});
		}
	}
	
			
	public async downloadPdf(){
		const progress$=new BehaviorSubject<number>(0);
		const message$=new BehaviorSubject<string>('Scanning...')
		const blob=await this.dialog.progress(this.pdfBlob(progress$,message$),progress$,message$);
		if(blob){
			downloadContent(this.filename('pdf'),blob,'application/pdf');
			this.dialog.closeAll();
		}
		else
		 	this.dialog.message('failed to download please try again')
	}

	private async pdfBlob(progress$:BehaviorSubject<number>,message$:BehaviorSubject<string>){
		if(await this.pageContainer.pdfBlob(progress$)){
			message$.next('Preparing Download...')
			return await this.pageContainer.pdfBlob(progress$);
		}else{
			return this.retry(progress$);
		}
	}

	private async retry(progress$:BehaviorSubject<number>){
		if(await this.pageContainer.pdfBlob(progress$)){
			return await this.pageContainer.pdfBlob(progress$)
		}else{
			return null;
		}
	}
}