import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { BehaviorSubject, Observable, combineLatest, op } from '@lib/rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { generateChartLines } from '../chart-transforms';
import { fragQuery } from '../../../../../shared-models/frag-query';
import { math } from '@lib/math';
import { chart } from '@lib/angular/chart';
import { Router } from '@angular/router';
import { combineInput, combineQunatityInput } from '../analysis/analysis.component';
import { BlastSite, DXXData, Machine, MachineLocationQuery } from '@models';
import { Color } from '@lib/color';
import { machinesDefaultColors } from 'src/colors';

@Component({
    selector: 'log-details',
    templateUrl: './log-details.component.html',
    styleUrls: ['./log-details.component.scss']
})
export class LogDetailsComponent {
    constructor(
        private dialog: DialogService,
        private fragQuery:FragQueryService,
        private router: Router
    ) { }

    public selectedDevice: string | undefined;
    public dataArr: math.Vec2Like[];
    public percentArr: math.Vec2Like[];
    public dxxArr:DXXData[];
    public intervalsArray: any[]=null;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly quantityTimeline$ = this.fragQuery.timelineQuantity$;
    
    public readonly chartLines$: Observable<chart.line.Series[]> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$
    ])
    .pipe(
        op.debounceTime(0),
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.map(([percents, input]) =>{
            this.psddata$.next(input?.intervals);
            return generateChartLines(percents, input)
        }),
        op.shareReplay(1)
    );

    public readonly blastSites$:Observable<{blastSite:BlastSite,machinePath:MachineLocationQuery.Interval.MachineLocationsWithPathColor}[]>=combineLatest([
        this.fragQuery.timelineQuantity$,
       this.fragQuery.addOnTimelineQuantity$
    ]).pipe(
        op.map(([input,addOn])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.filter(input=>input!==undefined),
        op.map(input=>input.intervals.map(interval=>{
            if(interval.blastSite && interval.machinePath){
                return {
                    blastSite:interval.blastSite,
                    machinePath: {
                        machineName:interval.machine ? interval.machine.name : null,
                        machineId:interval.machine ? interval.machine.id : null,
                        color:Color.hsv(358,25,80),
                        machineLocations:interval.machinePath ?? null
                    }
                }
            }else{
                return null;
            }
        }))
    )
    
    public readonly intervals$: Observable<fragQuery.timeline.sieveSizesAtPercents.Interval[]> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$
    ]).pipe(
        op.debounceTime(0),
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if(input[1] && input[1].intervals){
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf()-input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input]) => {
            return input ? input.intervals : null
        }),
        op.shareReplay(1)
    );

    public index$:Observable<number>=this.fragQuery.currentIndex.pipe(
        op.debounceTime(0),
        op.shareReplay(1)
    )
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly psddata$=new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Interval[]>(null)
    
    onSelectDevice(event: any): void {
        this.selectedDevice = event.target.value;
    }

    switchPage(){
        this.router.navigateByUrl(`/analytics`);
    }

    openReportResults() {
        this.dialog.input({ label: 'Comments', title: 'Report Results' })
    }
  
    onDataArrChange(dataArr: math.Vec2Like[]) {
      this.dataArr = dataArr;
    }
  
    onPercentArrChange(percentArr: math.Vec2Like[]) {
      this.percentArr = percentArr;
    }

    onDxxArrChange(dxxArr:DXXData[]){
        this.dxxArr=dxxArr
    }
}
