//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';

export class MachineQueryLocationByIntervalBody{
	public email:string;
	public authToken:string;
	public machineIds:number[];
	public begin:DateTime;
	public end:DateTime;
}

export namespace MachineQueryLocationByIntervalBody{
	export const meta=new ModelMeta<keyof Props>(
		"MachineQueryLocationByIntervalBody",
		[],
		[
			{
				name: "email",
				type: "string",
				decorators: {},
				notNull: true
			},{
				name: "authToken",
				type: "string",
				decorators: {},
				notNull: true
			},{
				name: "machineIds",
				type: "array",
				decorators: {},
				value: {
					type: "number"
				}
			},{
				name: "begin",
				type: "date",
				decorators: {},
				notNull: true
			},{
				name: "end",
				type: "date",
				decorators: {},
				notNull: true
			}
		]
	);

	interface Props{
		email:string;
		authToken:string;
		machineIds:number[];
		begin:DateTime;
		end:DateTime;
	}

}
