import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { } from 'googlemaps';
import { geojson } from '@lib/geojson';
import { MachineQueryService } from 'src/injectables/machine-query.service';
import { MachineLocationQuery } from '../../../../../shared-models/machine-query';
import { BlastSite, Device } from '@models';
import { Color } from '@lib/color';
import { DrawingManagerOption } from '../../new-ui/location/location.component';
import { MapService } from './map.service';

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})

export class MapComponent implements AfterViewInit, OnChanges {
    constructor(
        private readonly machineQuery: MachineQueryService,
        private readonly mapService: MapService
    ) { }

    @ViewChild('map') mapElement: ElementRef;
    @ViewChild('customControl', { static: true }) customControlDiv: ElementRef;
    @Input() MachinesPathLocations: MachineLocationQuery.Interval.MachineLocationsWithPathColor[];
    @Input() device: Device;
    @Input() blastSites: BlastSite[];
    @Input() draw: DrawingManagerOption = '';
    @Input() menuControl:boolean = false;
    @Input() showCurrentMachinesLocation: boolean = false;
    @Output() newSitePolygon: EventEmitter<geojson.GeometryPolygon> = new EventEmitter<geojson.GeometryPolygon>();
    @Output() addDeviceLocation: EventEmitter<CoordObj> = new EventEmitter<CoordObj>();
    @Output() changeDeviceLocation: EventEmitter<CoordObj> = new EventEmitter<CoordObj>();
    @Output() toggleMenu:EventEmitter<void>= new EventEmitter<void>

    map: google.maps.Map;
    machineTracePaths: TracePath[] = [];
    cornerLocations: CornerLocations[] = [];

    ngOnChanges(changes: SimpleChanges) {

        this.map=null;
        this.initMap();
        this.updateMap();
        
        if (changes['draw']) {
            this.mapService.initDrawingManager(
                this.draw,
                this.newSitePolygon,
                this.addDeviceLocation,
                this.map
            )
        }
    }

    ngAfterViewInit(): void {
        this.initMap();
        this.updateMap();
    }

    private initMap(){
        this.map=this.mapService.initMap({lat:this.device.lat,lng:this.device.long},this.mapElement);
    }

    private updateMap(){
        if(this.menuControl){
            const CustomControl=(controlDiv: HTMLElement, map: any)=> {
                const controlUI = document.createElement('div');
                controlUI.style.backgroundColor = 'white';
                controlUI.style.border = '2px solid #fff';
                controlUI.style.borderRadius = '2px';
                controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
                controlUI.style.cursor = 'pointer';
                controlUI.style.margin = '10px';
                controlUI.style.textAlign = 'center';
                controlDiv.appendChild(controlUI);
              
                const iconComponent = document.createElement('img');
                iconComponent.src = '../../../assets/mat-icons/menu.png';
                iconComponent.height=40;
            
                controlUI.appendChild(iconComponent);
                customControlDiv.appendChild(controlUI);
    
                controlUI.addEventListener('click', () => {
                  this.toggleMenu.emit();
                });
            };
    
            const customControlDiv = document.createElement('div');
            const customControl = CustomControl(customControlDiv, this.map);
            this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(customControlDiv);
        }


        if (this.device.lat && this.device.long) {
            this.mapService.addMarker({lat:this.device.lat,lng:this.device.long},this.device.name,this.map,this.changeDeviceLocation);
        }

        if(this.blastSites && this.blastSites.length>0){
            this.mapService.addBlastSites(this.blastSites,this.map);
        }
        else{
            this.machineQuery.sites$.subscribe(sites => {
                this.mapService.addBlastSites(sites,this.map);
            });
        }

        if(this.showCurrentMachinesLocation){
            this.machineQuery.machines$.subscribe(machines => {
                this.mapService.addMachines(machines,this.map);
            });
        }

        if (this.MachinesPathLocations && this.MachinesPathLocations.length > 0) {
            this.machineTracePaths = [];
            this.cornerLocations=[];
            this.MachinesPathLocations.forEach(machinelocations => {
                let startTime: number = 99999999999;
                let finishTime: number = 0;
                const loc: CoordObj[] = [];
                let cornerLoc: CornerLocations = {
                    start: { lat: 0, lng: 0 },
                    finish: { lat: 0, lng: 0 },
                    name: ''
                };
                machinelocations.machineLocations.forEach(Point => {
                    if (Point[0] < startTime) {
                        cornerLoc.start.lat = Point[1];
                        cornerLoc.start.lng = Point[2];
                        startTime = Point[0];
                    }
                    if (Point[0] > finishTime) {
                        cornerLoc.finish.lat = Point[1];
                        cornerLoc.finish.lng = Point[2];
                        finishTime = Point[0];
                    }
                    loc.push({ lng: Point[2], lat: Point[1] })
                })
                this.cornerLocations.push({ name: machinelocations.machineName, start: cornerLoc.start, finish: cornerLoc.finish })
                this.machineTracePaths.push({ points: loc, color: machinelocations.color })
            });
        }

        this.mapService.addTracePaths(this.machineTracePaths,this.cornerLocations,this.map);

    }
}

export interface CoordObj { 
    lat: number,
    lng: number
}

export interface CornerLocations {
    start: CoordObj,
    finish: CoordObj,
    name: string
}

export interface TracePath {
    points: CoordObj[],
    color: Color
}