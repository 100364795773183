<mat-drawer-container [hasBackdrop]="false">
    <mat-drawer #drawer [mode]="'side'">
        <div class="section start flex-column middle justify">
            <div>
                <button mat-flat-button color="white" (click)="showDrawingManager('polygon')"
                    class="mat-pickers button">
                    Add Blast Site
                </button>
                <button mat-flat-button color="white" (click)="showDrawingManager('point')" class="mat-pickers button">
                    Add Device Location
                </button>
            </div>

            <div class="full-height full-width">
                <mat-selection-list #machines>
                    <mat-list-option (click)="$event.stopPropagation();" *ngFor="let machineProp of machineProperties"
                        [value]="machineProp[0]" [selected]="machineProp[2]">
                        <button class="color-button" [style.background]="machineProp[1]"></button>
                        {{ machineProp[0].name }}
                    </mat-list-option>
                </mat-selection-list>
                <div class="flex-row center">
                    <button mat-flat-button color="primary"
                        (click)="saveSelectedOptions(machines.selectedOptions.selected)"
                        class="button mat-pickers full-width m">
                        Get
                    </button>
                </div>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="section start map-container">
        <div class="full-height full-width">
            <div *ngIf="(machineLocations$|async)" class="full-height full-width">
                <map class="full-height" [device]="this.device$|async"
                    [MachinesPathLocations]="(machineLocations$|async)"
                    [draw]="drawingManagerOption"
                    [showCurrentMachinesLocation]="true"
                    [menuControl]="true"
                    (newSitePolygon)="addnewPolygon($event)"
                    (addDeviceLocation)="addDeviceLocation($event)"
                    (changeDeviceLocation)="changeDeviceLocation($event)"
                    (toggleMenu)="toggleDrawer()">
                </map>
            </div>
            <div *ngIf="!(machineLocations$|async)"
                class="container-80 flex-column center middle full-width full-height">
                <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>