<div class="title flex-row justify">
  Custom Size Settings
  <mat-icon class="custom-sizes-btn" matTooltip="This Setting is for Current Device Only" [matTooltipPosition]="'below'">
    info
  </mat-icon>
</div>
<div class="container sizes-container">
  <div>
    <div class="flex-row center">
      <table>
        <tr *ngFor="let size of sizeOptions; let i = index">
          <td>
            <mat-form-field>
              <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$ | async)"
                [(ngModel)]="size.size" [value]="size.size"></measurement-input>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Label</mat-label>
              <mat-select [(ngModel)]="size.label">
                <mat-option value="Custom">Custom</mat-option>
                <mat-option [disabled]="!isFinesAvailable()" value="Fines">Fines</mat-option>
                <mat-option [disabled]="!isOversizedAvailable()" value="Oversize">Oversize</mat-option>
              </mat-select>
            </mat-form-field>
            <input type="color" [(ngModel)]="size.color" class="color-input">
          </td>
          <td>
            <mat-icon class="list-edit-icon" (click)="removeSizeRange(i)">cancel</mat-icon>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div class="flex-row center">
      <mat-form-field class="fields">
        <mat-label>Target Size</mat-label>
        <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$ | async)"
          [(ngModel)]="newSize"></measurement-input>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Label</mat-label>
        <mat-select [(ngModel)]="newLabel">
          <mat-option value="Custom">Custom</mat-option>
          <mat-option *ngIf="isFinesAvailable()" value="Fines">Fines</mat-option>
          <mat-option *ngIf="isOversizedAvailable()" value="Oversize">Oversize</mat-option>
        </mat-select>
      </mat-form-field>
      <input type="color" [(ngModel)]="newColor" class="color-input">
      <mat-icon class="list-edit-icon" (click)="addCustomSizeRange()">add_circle_outline</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()" [disabled]="!hasChanges()">Save</button>
</div>
