import {DateTime} from '../lib/date-time';
import {FragQueryTimelineSieveSizeAtPercentBody} from './public/frag-query-timeline-sieve-size-at-percent-body';
import {FragQueryTimelineQuantityBody} from './public/frag-query-timeline-quantity-body';
import {FragQueryTimelineRawBody} from './public/frag-query-timeline-raw-body';
import {DataIntervalTag} from './public/data-interval-tag';
import { BlastSite } from './public/blast-site';
import { Machine } from './public/machine';

export namespace fragQuery{
	export namespace timeline{
		export namespace raw{
			export type Body=FragQueryTimelineRawBody;
			export const Body=FragQueryTimelineRawBody;
			
			export interface Interval{
				begin:DateTime;
				end:DateTime;
				data:Record<string,number>;
			}
			
			export interface Response{
				begin:DateTime;
				end:DateTime;
				intervals:Interval[];
				tags:DataIntervalTag[];
			}
		}

		export namespace quantity{
			export type Body=FragQueryTimelineQuantityBody;
			export const Body=FragQueryTimelineQuantityBody;
			
			export interface Interval{
				begin:DateTime;
				end:DateTime;
				data:number;
				blastSite?:BlastSite;
				machine?:Machine;
				machinePath?:number[][];
				payload:number;
			}
			
			export interface Response{
				begin:DateTime;
				end:DateTime;
				intervals:Interval[];
				tags:DataIntervalTag[];
			}
		}
		
		export namespace sieveSizesAtPercents{
			export type Body=FragQueryTimelineSieveSizeAtPercentBody;
			export const Body=FragQueryTimelineSieveSizeAtPercentBody;
			
			export interface Interval{
				begin:DateTime;
				end:DateTime;
				imageUrl?:string;
				psd:{size:number,passing_percent:number}[];
				data:number[];
				customSizePercents:number[],
				slabs:number;
				thresholdCount:number;
			}
			
			export interface Response{
				deviceId:number,
				begin:DateTime;
				end:DateTime;
				percents:number[];
				intervals:Interval[];
				tags:DataIntervalTag[];
				summary?:{
					dvalues:Record<string,number>;
					psd:{size:number,passing_percent:number}[];
					totalWeight:number;
				}
			}

			export interface error{
				type:string,
				message:string
			}
		}
	}
}

export interface TimelineCustomSizes {
	size: number,
	color: string,
	label:'Fines'|'Oversize'|'Custom'
}
