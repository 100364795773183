import { Color } from '@lib/color';
import {DateTime} from '../lib/date-time';
import { MachineQueryLocationByIntervalBody } from './public/machine-query-location-by-interval-body';

export namespace MachineLocationQuery{
	export namespace Interval{
		export type Body=MachineQueryLocationByIntervalBody;
		export const Body=MachineQueryLocationByIntervalBody;
		
		export interface MachineLocations{
			machineName:string
			machineId:number,
			machineLocations:number[][]
		}
		export interface MachineLocationsWithPathColor{
			machineName:string
			machineId:number,
			color:Color,
			machineLocations:number[][]
		}
		export interface Response{
			begin:DateTime;
			end:DateTime;
			locationPoints:MachineLocations[];
		}
		export interface error{
			type:string,
			message:string
		}
	}
}
