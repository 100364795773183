import { Component, ElementRef, ViewChild } from '@angular/core';
import { DialogAddSiteComponent } from '@app/old-ui/dialog/dialog-add-site/dialog-add-site.component';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { User$, WebClient } from '@injectables';
import { machinesDefaultColors } from "src/colors";
import { Observable, combineLatest } from 'rxjs';
import { geojson } from '@lib/geojson'
import { MachineQueryService } from 'src/injectables/machine-query.service';
import { DialogSetDeviceLocationComponent } from '@app/old-ui/dialog/dialog-set-device-location/dialog-set-device-location.component';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { Device, Machine, MachineLocationQuery } from '@models';
import { Color } from '@lib/color';
import { op } from '@lib/rxjs';
import { CoordObj } from '../../utilities/map/map.component';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent {
    constructor(
        private readonly machineQuery: MachineQueryService,
        public readonly user$: User$,
        private readonly dialog:DialogService,
        private readonly webClient:WebClient,
    ) { }

    private readonly addSiteDialog=DialogAddSiteComponent;
    private readonly setDevceLocation=DialogSetDeviceLocationComponent;
    public readonly machines$ = this.machineQuery.machines$;
    public readonly machine$ = this.machineQuery.machine$;
    public readonly selectedMachines$= this.machineQuery.selectedMachines$;
    public readonly device$ = this.machineQuery.device$;
    public readonly devices$ = this.machineQuery.devices$;
    public readonly sites$= this.machineQuery.sites$;
    public readonly duration$=this.machineQuery.duration$;
    public readonly durationOptions$=this.machineQuery.durationOptions;
    public drawingManagerOption: DrawingManagerOption = '';

    @ViewChild('machines') machinesList: MatSelectionList;
    @ViewChild('drawer') drawer: MatDrawer;

    colors= machinesDefaultColors;
    machineProperties:[Machine,Color,boolean][]=[];

    public readonly deviceClient=this.webClient.model.device;

    ngOnInit() {
        this.machines$.subscribe(machines => {
            let selectedMachinesList:Machine[];
            this.selectedMachines$.subscribe(selectedMachines=>{
                selectedMachinesList=selectedMachines
            });
            let i = 0;
            machines.forEach(machine => {
                if(selectedMachinesList.includes(machine)){
                    this.machineProperties.push([machine, this.colors[i],true]);
                }else{
                    this.machineProperties.push([machine, this.colors[i],false]);
                }
                i++;
            });
        })
    }

    public toggleDrawer(){
        this.drawer.toggle();
    }

    public saveSelectedOptions(selectedOptions: MatListOption[]) {
        this.toggleDrawer();
        const selectedValues: Machine[] = selectedOptions.map(option => {
            return option.value;
        });
        this.selectedMachines$.next(selectedValues);
    }
    
    public async showDrawingManager(type:DrawingManagerOption){
        this.toggleDrawer();
        this.drawingManagerOption=type;
    }
    
    public async addnewPolygon(newPolygon:geojson.GeometryPolygon){
        await this.dialog.open(this.addSiteDialog,newPolygon);
        this.drawingManagerOption='';
    }

    public async addDeviceLocation(coords:CoordObj){
        await this.dialog.open(this.setDevceLocation,coords);
        this.drawingManagerOption='';
    }

    public async changeDeviceLocation(coords:CoordObj){
        let saveDevice:Device;
        if(await this.dialog.confirm({message:'Are you sure you want to change the location of the device?'})){
            this.device$.subscribe(device=>{
                device.lat=coords.lat,
                device.long=coords.lng
                saveDevice=device
            })
            await this.dialog.await(this.deviceClient.save(saveDevice));
        }else{
            this.device$.subscribe(device=>{
                saveDevice=device
            })
            this.device$.next(saveDevice)
        }
    }

    public readonly machineLocations$:Observable<MachineLocationQuery.Interval.MachineLocationsWithPathColor[]>=combineLatest(
        [
            this.machineQuery.machineLocationForInterval$,
            this.machineQuery.machines$
        ]
    ).pipe(
        op.map(([response,machines])=>getMachinecolor(response,this.machineProperties,machines))
    )
};

export type DrawingManagerOption = '' | 'polygon' | 'point';

const getMachinecolor=(machineLocationsResponse:MachineLocationQuery.Interval.Response,machineProperties:[Machine,Color,boolean][],machines:Machine[]): MachineLocationQuery.Interval.MachineLocationsWithPathColor[] =>{
    if(machineLocationsResponse){
        const array= machineLocationsResponse.locationPoints.map(machineLocations=>{
            return {
                machineName:machineLocations.machineName,
                machineId:machineLocations.machineId,
                color:machineProperties.find(machine => machine[0].id === machineLocations.machineId)[1],
                machineLocations:machineLocations.machineLocations
            }
        });
        return array;
    }
    return null;
}