<div class="section start">
</div>
<div class="section">
    <div class="flex-row between section">  <div class="full-width go-to-button"><button
        mat-raised-button
        color="accent"
        class="image-label"
        (click)="switchPage()"
          >
          Go to Analytics
    </button></div>   
    <div class="full-width"><percent-selector [value]="newPercents$ | async" (valueChanged)="newPercents$.next($event)"></percent-selector></div></div>
</div>
<div class="section end flex-percent flex-wrapper">
    <div class="container container-33">
        <snapshot-card-analysis [series]="intervals$ | async" [snapType]="'details'" class="container container-33">
        </snapshot-card-analysis>
        <mat-card *ngIf="(quantityTimeline$|async)===undefined" class="spinner-container">
            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
        </mat-card> 
        <div *ngIf="(quantityTimeline$|async)!==undefined && (blastSites$|async) && (blastSites$|async)[index$|async]" class="map-container">
            <mat-card *ngIf="(blastSites$|async) && (blastSites$|async)[index$|async]" class="map-container container">
                <div class="container flex-row center font-large" style="font-weight: 500;">
                    Machine: {{(blastSites$|async)[index$|async].machinePath.machineName}}
                </div>
                <div class="container flex-row center font-large" style="font-weight: 500;">
                    BlastSite: {{(blastSites$|async)[index$|async].blastSite.name}}
                </div>
                <map
                [blastSites]="[(blastSites$|async)[index$|async].blastSite]"
                [MachinesPathLocations]="[(blastSites$|async)[index$|async].machinePath]"
                [device]="(device$|async)"
                class="full-width full-height"
                ></map>
            </mat-card>
        </div>
    </div>
    <div class="container container-67">
        <mat-card *ngIf="(chartLines$|async)===undefined" class="spinner-container">
            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
        </mat-card>
        <mat-card *ngIf="(chartLines$|async)!==undefined && (chartLines$|async)!==null && (chartLines$|async).length===0" class="spinner-container">
            <div class="container centered-content">
                <div>
                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                </div>
                <div>No Data Found</div>
            </div>
        </mat-card>
        <div *ngIf="(chartLines$|async)!==undefined && (chartLines$|async)!==null && (chartLines$|async).length!==0">
            <mat-card class="container">
                <psd-chart [index]="index$|async" [data]="psddata$|async" (dxxArrEmitter)="onDxxArrChange($event)" (percentArrEmitter)="onPercentArrChange($event)" (dataArrEmitter)="onDataArrChange($event)"></psd-chart>
            </mat-card>
            <analysis-table class="container" [dxxArr]="dxxArr" [dataArr]="dataArr" [percentArr]="percentArr"></analysis-table>
        </div>
    </div>
</div>