import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Device, User } from '@models';
import { DialogService } from '../dialog.service';
import { WebClient } from '@injectables';
import { OverrideSubject, op } from '@lib/rxjs';
import { CoordObj } from '@app/utilities/map/map.component';

@Component({
  selector: 'app-dialog-set-device-location',
  templateUrl: './dialog-set-device-location.component.html',
})
export class DialogSetDeviceLocationComponent {

  static readonly config:MatDialogConfig<CoordObj>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;
    constructor(
		public readonly user:User,
		private readonly webClient:WebClient,
		private readonly host:MatDialogRef<boolean>,
		private readonly dialog:DialogService,
		@Inject(MAT_DIALOG_DATA) private readonly coords:CoordObj,
	){
	}

    public readonly devices$=this.webClient.model.device.search$({
        where:{
            lat:null,
            long:null
        }
	});

    public defaultDevice$ = this.devices$.pipe(
		op.map((v) => v?.[0] ?? null)
	);

    public readonly device$ = new OverrideSubject(this.defaultDevice$);

	public readonly deviceClient=this.webClient.model.device;

	public cancel(){
		this.host.close(false);
	}

	public async save(){
        let saveDevice:Device;
        this.device$.subscribe(device=>{
            device.lat=this.coords.lat,
            device.long=this.coords.lng
            saveDevice=device
        })
        await this.dialog.await(this.deviceClient.save(saveDevice));
		this.host.close(true);
	}
}
