<div class="controls">
	<div class="flex-row">
        <div class="icon-box" (click)="toggleFileType('CSV')" [ngClass]="{'active': fileType==='CSV'}">
            <mat-icon svgIcon="csv"></mat-icon>
            <span>Get CSV</span>
        </div>
        <div class="icon-box" (click)="toggleFileType('PDF')" [ngClass]="{'active': fileType==='PDF'}">
            <mat-icon svgIcon="pdf"></mat-icon>
            <span>Get PDF</span>
        </div>
    </div>
	<div *ngIf="fileType==='PDF'">
		<mat-form-field compact class="full-width">
			<mat-label>Page Size</mat-label>
			<mat-select [(ngModel)]="selectedPageSize">
				<mat-option *ngFor="let pageSize of pageSizes" [value]="pageSize">
					{{pageSize}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field compact class="full-width">
			<mat-label>Orientation</mat-label>
			<mat-select [(ngModel)]="orientation">
				<mat-option [value]="'landscape'">
					Landscape
				</mat-option>
				<mat-option [value]="'portrait'">
					Portrait
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field class="full-width">
			<mat-label>Zoom</mat-label>
			<mat-select [(ngModel)]="zoom">
				<mat-option *ngFor="let option of zoomOptions" [value]="option[1]">
					{{option[0]}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div *ngIf="fileType==='CSV' && (route$|async)[1]==='time-interval'">
		<mat-form-field compact class="full-width">
			<mat-label>Aggregated PSD Data Format</mat-label>
			<mat-select [(ngModel)]="psdDataFormatInCSV">
				<mat-option [value]="'single-row'">
					Single Row
				</mat-option>
				<mat-option [value]="'multiple-row'">
					Multiple Rows
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<mat-divider class="row"></mat-divider>
	<div>
		<ng-content select="[controls]"></ng-content>
	</div>
	<div class="actions">
		<button mat-button [mat-dialog-close]="true">Cancel</button>
		<button *ngIf="fileType==='CSV'" mat-flat-button color="primary" (click)="downloadCsv()">Download CSV</button>
		<button *ngIf="fileType==='PDF'" mat-flat-button color="primary" (click)="downloadPdf()">Download PDF </button>
	</div>
</div>

<report-page-container
	#pageContainer
	[selectedPageSize]="selectedPageSize"
	[orientation]="orientation"
	[zoom]="zoom"
>
	<ng-content select="[pages]">
	</ng-content>
</report-page-container>