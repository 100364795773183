import { ElementRef, EventEmitter, Injectable } from "@angular/core";
import { CoordObj, CornerLocations, TracePath } from "./map.component";
import { BlastSite, Machine } from "@models";
import { geojson } from "@lib/geojson";
import { DrawingManagerOption } from "../../new-ui/location/location.component";

@Injectable({
	providedIn: "root",
})
export class MapService{

    polygonGeoJson: geojson.GeometryPolygon = {
        type: 'Polygon',
        coordinates: []
    };

    drawingManager: google.maps.drawing.DrawingManager;
    polygonCompleteListener: google.maps.MapsEventListener;
    markerCompleteListener: google.maps.MapsEventListener;

    public initMap(center:CoordObj,mapElement:ElementRef){
        const mapProperties = {
            center: new google.maps.LatLng(center.lat ?? 45, center.lng ?? 45),
            zoom: center ? 15 : 2,
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            mapTypeControl: false,
        };
        return new google.maps.Map(mapElement.nativeElement, mapProperties);
    }

    public initDrawingManager(
        drawingOption:DrawingManagerOption,
        newsitePolygon:EventEmitter<geojson.GeometryPolygon>,
        addDeviceLocation: EventEmitter<CoordObj>,
        map:google.maps.Map
    ){
        if (drawingOption === '') {
            if (this.drawingManager) {
                this.drawingManager.setMap(null);
                if (this.polygonCompleteListener) {
                    google.maps.event.removeListener(this.polygonCompleteListener);
                    this.polygonCompleteListener = null;
                }
                if (this.markerCompleteListener) {
                    google.maps.event.removeListener(this.markerCompleteListener);
                    this.markerCompleteListener = null;
                }
            }
        }

        if (drawingOption === 'polygon') {
            if (!this.drawingManager) {
                this.drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: google.maps.drawing.OverlayType.POLYGON,
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                });
            } else {
                this.drawingManager.setOptions({
                    drawingMode: google.maps.drawing.OverlayType.POLYGON,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                });
            }

            this.drawingManager.setMap(map);

            if (!this.polygonCompleteListener) {
                this.polygonCompleteListener = google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
                    const coordinatesArray = [];
                    this.polygonGeoJson.coordinates = [];
                    for (let point of polygon.getPath().getArray()) {
                        let coord: geojson.Coord = [point.lat(), point.lng()];
                        coordinatesArray.push(coord);
                    }
                    this.polygonGeoJson.coordinates.push(coordinatesArray);
                    newsitePolygon.emit(this.polygonGeoJson);
                });
            }
        }

        else if (drawingOption === 'point') {
            if (!this.drawingManager) {
                this.drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: google.maps.drawing.OverlayType.MARKER,
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.MARKER],
                    },
                });
            } else {
                this.drawingManager.setOptions({
                    drawingMode: google.maps.drawing.OverlayType.MARKER,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.MARKER],
                    },
                });
            }
            this.drawingManager.setMap(map);

            if (!this.markerCompleteListener) {
                this.markerCompleteListener = google.maps.event.addListener(this.drawingManager, 'markercomplete', (marker: google.maps.Marker) => {
                    addDeviceLocation.emit({ lat: marker.getPosition().lat(), lng: marker.getPosition().lng() });
                    this.drawingManager.setDrawingMode(null);
                });
            }
        }
    }

    public addMarker(point:CoordObj,title:string,map:google.maps.Map,editLocationEmitter:EventEmitter<CoordObj>,iconPath?:string){
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(point.lat ?? 0, point.lng ?? 0),
            title: title,
            draggable: true,
            icon:iconPath ?{
                url: iconPath,
                scaledSize: new google.maps.Size(40, 40)
            }: null,
            zIndex:10
        });
        marker.setMap(map);

        marker.addListener('dragend', (event) => {
            const position = marker.getPosition() as google.maps.LatLng;
            editLocationEmitter.emit({ lat: position.lat(), lng: position.lng() })
        });
    }

    public addBlastSites(blastSites:BlastSite[],map:google.maps.Map){
        for (const blastSite of blastSites) {
            let infowindow: google.maps.InfoWindow;
            const geoJson: geojson.GeometryPolygon = JSON.parse(blastSite.locations);

            const coordinates: google.maps.LatLng[] = geoJson.coordinates[0].map((point) => {
                return new google.maps.LatLng(point[0], point[1]);
            });

            const feature = new google.maps.Data.Feature({
                geometry: new google.maps.Data.Polygon([coordinates]),
            });

            map.data.add(feature);

            const contentString = `
                <div>
                    <h4>Blast Site : ${blastSite.name}</h4>
                </div>
            `;

            infowindow = new google.maps.InfoWindow({
                content: contentString,
                position: coordinates[0],
                disableAutoPan: true,
                zIndex:1
            });

            infowindow.open(map);
        }
    }

    public addCircle(center:CoordObj,map:google.maps.Map,color:string){
        const circle = new google.maps.Circle({
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 5,
            fillColor: color,
            fillOpacity: 0.35,
            map: map,
            center: new google.maps.LatLng(center.lat, center.lng),
            radius: 10,
        });

        return circle;
    }

    public addMachines(machines:Machine[],map:google.maps.Map){

        const machineCircleColor:string="#EE4B2B"
        for (const machine of machines) {
            let infowindow: google.maps.InfoWindow;

            const machineCircle=this.addCircle({lat:machine.lastLat,lng:machine.lastLong},map,machineCircleColor);

            const contentString = `
                <div>
                    <h4>Machine : ${machine.name}</h4>
                </div>
            `;

            infowindow = new google.maps.InfoWindow({
                content: contentString,
                position: machineCircle.getCenter(),
                disableAutoPan: true
            });

            machineCircle.addListener('mouseover', (event) => {
                infowindow.open(map);
            })

            machineCircle.addListener('mouseout', () => {
                if (infowindow) {
                    infowindow.close();
                }
            });
        }
    };

    public addPolyline(points:CoordObj[],map:google.maps.Map,color:string){
        const path = new google.maps.Polyline({
            path: points,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [{
                icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor: 'white',
                },
                offset: '50px',
                repeat: '150px',
            }],
        });
        path.setMap(map);
    }

    public addTracePaths(tracepaths:TracePath[],cornerLocations:CornerLocations[],map:google.maps.Map){
        tracepaths.forEach(tracePath => {
            this.addPolyline(tracePath.points,map,`${tracePath.color}`);
        });

        cornerLocations.forEach(cornerLocation => {
            this.addMarker({lat:cornerLocation.start.lat, lng:cornerLocation.start.lng},cornerLocation.name,map,null,'../../../assets/mat-icons/start.png')
            this.addMarker({lat:cornerLocation.finish.lat, lng:cornerLocation.finish.lng},cornerLocation.name,map,null,'../../../assets/mat-icons/finish-flag.png')
        })
    }
}