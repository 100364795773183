import { Color } from "@lib/color";
import { math } from "@lib/math";

export const percentageDefaultColors=new Map([
	[10,Color.hsv(240,1,1)],
	[20,Color.hsv(120,1,1)],
	[30,Color.hsv(math.lerp(80,120,2/3),1,1)],
	[40,Color.hsv(math.lerp(80,120,1/3),1,1)],
	[50,Color.hsv(80,1,1)],
	[60,Color.hsv(math.lerp(0,80,2/3),1,1)],
	[70,Color.hsv(math.lerp(0,80,1/3),1,1)],
	[80,Color.hsv(0,1,1)],
	[90,Color.hsv(0,1,1)],
	[100,Color.hsv(0,1,1)],
]);

export const machinesDefaultColors: Color[] = [
    Color.hsv(0, 1, 1),       // Red
    Color.hsv(120, 1, 1),     // Green
    Color.hsv(240, 1, 1),     // Blue
    Color.hsv(30, 1, 0.8),    // Orange
    Color.hsv(300, 1, 0.8),   // Magenta
    Color.hsv(180, 1, 0.8),   // Cyan
    Color.hsv(60, 1, 0.8),    // Yellow
    Color.hsv(210, 1, 0.8),   // Azure
    Color.hsv(270, 1, 0.8),   // Indigo
    Color.hsv(90, 1, 0.8),    // Lime
    Color.hsv(330, 1, 0.8),   // Rose
    Color.hsv(150, 1, 0.8),   // Spring Green
    Color.hsv(255, 1, 0.8),   // Violet
    Color.hsv(45, 1, 0.8),    // Gold
    Color.hsv(75, 1, 0.8),    // Maroon
    Color.hsv(15, 1, 0.8),    // Dark Orange
    Color.hsv(345, 1, 0.8),   // Pink
    Color.hsv(285, 1, 0.8),   // Lavender
    Color.hsv(225, 1, 0.8),   // Aqua
    Color.hsv(195, 1, 0.8),   // Sky Blue
    Color.hsv(105, 1, 0.8),   // Lime Green
    Color.hsv(135, 1, 0.8),   // Dark Cyan
    Color.hsv(15, 1, 0.7),    // Dark Orange
    Color.hsv(345, 1, 0.7),   // Pink
    Color.hsv(285, 1, 0.7),   // Lavender
    Color.hsv(225, 1, 0.7),   // Aqua
    Color.hsv(195, 1, 0.7),   // Sky Blue
    Color.hsv(105, 1, 0.7),   // Lime Green
    Color.hsv(135, 1, 0.7),   // Dark Cyan
    Color.hsv(15, 1, 0.6),    // Dark Orange
    Color.hsv(345, 1, 0.6),   // Pink
    Color.hsv(285, 1, 0.6),   // Lavender
    Color.hsv(225, 1, 0.6),   // Aqua
    Color.hsv(195, 1, 0.6),   // Sky Blue
    Color.hsv(105, 1, 0.6),   // Lime Green
    Color.hsv(135, 1, 0.6),   // Dark Cyan
    Color.hsv(15, 1, 0.5),    // Dark Orange
    Color.hsv(345, 1, 0.5),   // Pink
    Color.hsv(285, 1, 0.5),   // Lavender
    Color.hsv(225, 1, 0.5),   // Aqua
    Color.hsv(195, 1, 0.5),   // Sky Blue
];


export const percentageNewColors=new Map([
	[10,'#DF0C0C'],
	[20,'#980040'],
	[30,'#98007F'],
	[40,'#3A0098'],
	[50,'#003D98'],
	[60,'#00986A'],
	[70,'#009818'],
	[80,'#4C9800'],
	[90,'#987F00'],
	[100,'#980000'],
]);
