import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { WebClient } from '@injectables';
import { User,BlastSite } from '@models';
import { DialogService } from '../dialog.service';
import { geojson } from '@lib/geojson';

@Component({
  selector: 'app-dialog-add-site',
  templateUrl: './dialog-add-site.component.html',
})
export class DialogAddSiteComponent {
	static readonly config:MatDialogConfig<geojson.GeometryPolygon>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;
  constructor(
		public readonly user:User,
		private readonly webClient:WebClient,
		private host:MatDialogRef<boolean>,
		@Inject(MAT_DIALOG_DATA) private readonly polygon:Input,
		private dialog:DialogService
	){
	}

	public blastSite:BlastSite=new BlastSite;
	public readonly blastRestClient=this.webClient.model.blastSite;
	public name= this.blastSite.name;

	public valid(){
		if(!this.name)
			return false;
		return true;
	}

	public cancel(){
		this.host.close(false);
	}

	public async save(){
		this.blastSite.clientId= this.user.clientId;
		this.blastSite.name=this.name;
		this.blastSite.locations=JSON.stringify(this.polygon);
		await this.dialog.await(this.blastRestClient.save(this.blastSite));
		this.host.close(true);
	}
}
