import {models} from '@models';

export interface NavigationMenuItem{
	route?:string;
	name:string;
	permissionsNeeded?:number;
	environmentType?:'cloud'|'device';
	children?:{[key:string]:NavigationMenuItem};
	default?:boolean;
	routerLink?:string;
}

export type NavigationMenu={[key:string]:NavigationMenuItem};

export const navigationMenu:NavigationMenu={
	dashboard: {
		name: 'Dashboard',
		children: {
			'live-feed': {
				name: 'Live Feed',
				default: true,
				environmentType: 'device',
			},
			'psd-timeline': {
				name: 'PSD Timeline',
				default: true,
			},
			'quantity-timeline': {
				name: 'Quantity Timeline',
				default: true,
			},
			'tags': {
				name: 'Tags',
			},
		},
	},
	manage: {
		name: 'Users & Devices',
		permissionsNeeded: 1,
		children: {
			client: {
				name: 'Clients*',
				permissionsNeeded: 2,
			},
			user: {
				name: 'Users',
				permissionsNeeded: 1,
				default: true,
			},
			device: {
				name: 'Devices',
				permissionsNeeded: 1,
				default: true,
			},
		},
	},
	home:{
		name:'home',
		default:true,
		permissionsNeeded:0
	},
	analytics:{
		name:'analytics',
		default:true
	},
	details:{
		name:'details',
		default:true
	},
	settings:{
		name:'settings',
		default:true
	},
	report:{
		name:'report',
		default:true,
		children:{
			'time-interval':{
				name:'time-interval',
			},
			'data-interval':{
				name:'data-interval',
			}
		}
	},
	'shift-analytics':{
		name:'shift analytics',
		default:true
	},
	trace:{
		name:'trace',
		default:true
	},
	user:{
		name:'user',
		default:true,
		children:{
			preferences:{
				name:'preferences',
				permissionsNeeded:0
			},
			api_settings:{
				name:'api-settings',
				permissionsNeeded:1
			}
		}
	},
	preferences:{
		name:'preferences',
		default:true
	},
	database: {
		name: 'Database*',
		permissionsNeeded: 2,
		children: {}
	},
	query: {
		name: 'Queries*',
		permissionsNeeded: 2,
		children: {
			'timeline-raw': {
				name: 'timeline/raw',
			},
			'timeline-quantity': {
				name: 'timeline/quantity',
			},
			'timeline-sieve-sizes-at-percents': {
				name: 'timeline/sieve-sizes-at-percents',
			},
		}
	}
};

for(const model of models){
	if(!model.meta.rest.route)
		continue;
	navigationMenu['database'].children[model.meta.rest.route]={
		name: model.meta.name,
	};
}

function processMenu(
	menu:NavigationMenu,
	routerLink:string,
){
	for(const [route,item] of Object.entries(menu)){
		if(!item.route)
			item.route=route;
		item.permissionsNeeded??=0;
		item.routerLink=routerLink+'/'+item.route;
		if(item.children)
			processMenu(item.children,item.routerLink);
	}
	return menu;
}
processMenu(navigationMenu,'');
