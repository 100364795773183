//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';
import {WhereTable,RestTypes as _RestTypes} from '../../lib/model-meta';

export class Machine{
	public id:number;
	public clientId:number;
	public name:string;
	public roverId:number;
	public lastUpdate:number=1;
	public lastLat:number|null=null;
	public lastLong:number|null=null;
}

export namespace Machine{
	export const meta=new ModelMeta<keyof Props>(
		"Machine",
		[],
		[
			{
				name: "id",
				type: "serial",
				decorators: {},
				uniqueKey: true,
				notNull: true
			},{
				name: "clientId",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "name",
				type: "string",
				decorators: {},
				notNull: true
			},{
				name: "roverId",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "lastUpdate",
				type: "int",
				decorators: {},
				default: 1,
				optional: true,
				notNull: true
			},{
				name: "lastLat",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "lastLong",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			}
		]
	);

	interface Props{
		id:number;
		clientId:number;
		name:string;
		roverId:number;
		lastUpdate?:number;
		lastLat?:number|null;
		lastLong?:number|null;
	}


	meta.generateRest("machine",[
		{
			name: "email",
			type: "string",
			decorators: {}
		},{
			name: "authToken",
			type: "string",
			decorators: {}
		}
	]);

	export interface ReadableProps{
		id:number;
		clientId:number;
		name:string;
		roverId:number;
		lastUpdate?:number;
		lastLat?:number|null;
		lastLong?:number|null;
	}

	export interface ReadablePrimitiveProps{
		id:number;
		clientId:number;
		name:string;
		roverId:number;
		lastUpdate?:number;
		lastLat?:number|null;
		lastLong?:number|null;
	}

	namespace body{

		interface WritableProps{
			clientId:number;
			name:string;
			roverId:number;
			lastUpdate?:number;
			lastLat?:number|null;
			lastLong?:number|null;
		}

		export interface Auth{
			email?:string;
			authToken?:string;
		}

		export interface Get extends Auth{
			where:number;
			fields?:(keyof ReadableProps)[];
		}

		export interface Put extends Auth{
			where:number;
			fields:Partial<WritableProps>;
		}

		export interface Post extends Auth{
			fields:Partial<WritableProps>;
		}

		export interface Delete extends Auth{
			where:number;
		}

		export interface Count extends Auth{
			where?:WhereTable<keyof ReadablePrimitiveProps>;
		}

		export interface Search extends Auth{
			fields?:(keyof ReadablePrimitiveProps)[];
			where?:WhereTable<keyof ReadablePrimitiveProps>;
			range?:[number,number];
			sort?:Partial<Record<keyof ReadablePrimitiveProps,'+'|'-'>>;
		}
	}

	namespace _response{
		export type Get=Partial<ReadableProps>;
		export type Search=Partial<ReadablePrimitiveProps>[];
	}

	export interface RestTypes extends _RestTypes{
		body:{
			Auth:body.Auth;
			Get:body.Get;
			Put:body.Put;
			Post:body.Post;
			Delete:body.Delete;
			Count:body.Count;
			Search:body.Search;
		}

		response: {
			Get:_response.Get;
			Search:_response.Search;
		}
	}

}
