export const environment={
	appName: 'frag-camera',
	appTitle: 'Strayos Netra',
	production: false,
	backendUrl: '',
	sentryUrl:'',
	name:'',
	showDarkMode:false,
	showVideoTab:false,
	signalingServerUrl:'',
	fileStorageSas: '?sv=2021-10-04&st=2023-09-01T17%3A45%3A22Z&se=2043-09-02T17%3A45%3A00Z&sr=c&sp=r&sig=avhTpUTgvvzl28mNV7ZbJzos81C9Ipt6TuTNiwn33oY%3D',
	googleMapsAPIKey:'AIzaSyDoMibEPR0ubzMiH0uHa4P_-pDrYGXIF6s'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
