import { Component, OnInit } from '@angular/core';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Device, TimelineCustomSizes } from '@models';
import { WebClient } from '@injectables';

@Component({
  selector: 'app-dialog-custom-sizes',
  templateUrl: './dialog-custom-sizes.component.html',
  styleUrls: ['./dialog-custom-sizes.component.scss']
})
export class DialogCustomSizesComponent implements OnInit {
  device: Device;
  public sizeOptions: TimelineCustomSizes[] = [];
  public newSize: number = null;
  public newLabel: 'Fines'|'Custom'|'Oversize' = 'Custom'
  public newColor: string = '#000'
  public changesMade: boolean = false;

  constructor(
    public readonly fragQuery: FragQueryService,
    public readonly dialog: DialogService,
    private readonly snackBar: MatSnackBar,
    private readonly webClient: WebClient
  ) { }

  public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
  public readonly devices$ = this.fragQuery.devices$;
  public readonly device$ = this.fragQuery.device$;
  public readonly restClient = this.webClient.model.device;

  ngOnInit(): void {
    this.device$.subscribe((device: Device) => {
      this.device = device;
      if (this.device.timelineCustomSizes) {
        let customSizeOptions = JSON.parse(this.device.timelineCustomSizes);
        if (customSizeOptions[0].size != null) {
          this.sizeOptions = customSizeOptions;
        } else {
          this.sizeOptions = [];
        }
      } else {
        this.sizeOptions = [];
      }
    });
  }

  hasChanges() {
    return this.changesMade;
  }


  addCustomSizeRange() {
    if (this.newSize != null) {
      const newRange = { size: this.newSize,label:this.newLabel, color: this.newColor, };
      this.sizeOptions = [...this.sizeOptions, newRange];
      this.newSize = null;
      this.changesMade = true;
    } else {
      alert('Invalid size range');
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  removeSizeRange(index: number) {
    this.sizeOptions.splice(index, 1);
    this.changesMade = true;
  }

  isFinesAvailable(){
    let isFinesUsed=false;
    this.sizeOptions.forEach((size)=>{
      if(size.label == 'Fines'){
        isFinesUsed= true;
      }
    });
    return !isFinesUsed;
  }

  isOversizedAvailable(){
    let isOversizedUsed=false;
    this.sizeOptions.forEach((size)=>{
      if(size.label == 'Oversize'){
        isOversizedUsed= true;
      }
    });
    return !isOversizedUsed;
  }

  valid() {
    return this.sizeOptions.length;
  }

  cancel() {
		this.dialog.closeAll();
	}

  async save() {
    try {
      this.sizeOptions.sort((a, b) => a.size - b.size);
      this.device.timelineCustomSizes = JSON.stringify(this.sizeOptions);
      await this.dialog.await(this.restClient.save(this.device));
      this.snackBar.open('Settings saved successfully', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-success']
      });
      this.dialog.closeAll();
      this.fragQuery.trigger$.next();
      this.changesMade = false; 
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  }
}